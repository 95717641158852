import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {ProcessStage} from '../types'

import {useApi} from './useApi'

export const QueryKey = 'config-processStages'

export const useProcessStages = <T = Map<string, string>>(
  plantId: string,
  options?: UseQueryOptions<Map<string, string>, AxiosError, T>
) => {
  const {publicApi} = useApi()
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<Map<string, string>, AxiosError,T>(
    [QueryKey, plantId, language],
    async () => {
      const path = `/shifthandover/${plantId}/process-stages`
      const params = {params: {language}}

      const response = await publicApi.get<ProcessStage[]>(path, params)

      return new Map(
        response.data.map((stage: ProcessStage) => {
          return [stage.code, stage.name]
        })
      )
    },
    {
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
      ...options
    }
  )
}
