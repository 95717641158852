import {isUndefined} from 'lodash'
import {generatePath} from 'react-router-dom'

import {GRAPH_DETAIL_ROUTE, PLANT_ANALYSIS_LIST_ROUTE, PLANT_STOPPAGE_LIST_ROUTE} from '../consts'
import {MAINTAIN_HOST} from '../hproduce/config/maintain'
import {AnalysisListType, GraphDetailScreens} from '../types'

import {getHost} from './route.utils'

export const getMaintainAnalysisUrl = (plantId: string, analysisId?: number) => {
  if (isUndefined(analysisId)) {
    return '#'
  }

  const stageSpecificHost = getHost(MAINTAIN_HOST)
  const graphDetailPath = generatePath(GRAPH_DETAIL_ROUTE, {
    plantId,
    analysisId,
    type: GraphDetailScreens[0]
  })
  return stageSpecificHost + graphDetailPath
}

export const getMaintainAnalysisListUrl = (plantId: string, listType?: AnalysisListType) => {
  const stageSpecificHost = getHost(MAINTAIN_HOST)
  const plantAnalisysListPath = generatePath(PLANT_ANALYSIS_LIST_ROUTE, {plantId})
  const plantAnalisysListTypePath = listType
    ? `${plantAnalisysListPath}?type=${listType}`
    : plantAnalisysListPath

  return stageSpecificHost + plantAnalisysListTypePath
}

export const getMaintainRcfaStoppageListUrl = (plantId: string) => {
  const stageSpecificHost = getHost(MAINTAIN_HOST)
  const plantRcfaStoppageListPath = generatePath(PLANT_STOPPAGE_LIST_ROUTE, {plantId})
  const filters = JSON.stringify([{property: 'plant', values: [plantId], operation: 'In'}])
  const search = new URLSearchParams({filters}).toString()

  return `${stageSpecificHost}${plantRcfaStoppageListPath}?${search}`
}
